import React from 'react';
import { useLocation } from 'react-router-dom';
import ReactDOM from 'react-dom';
import { nanoid } from 'nanoid';
import { Notification } from '../components/Notification';
import { Websockets } from '../utils/ws';
import { noop } from '../utils/noop';
import { normalizeItems } from '../utils/normalize';
import networksConfig from '../networksConfig.json';

/** @typedef {{ name: string, path: string, HTTP_API: string, WS_API: string, SERVER_ADDR: string }} FS$Network */

// /** @type {{networks: FS$Network[]}} */
const { networks } = networksConfig;

/** @type {{ entities: Object, results: string[], loading: boolean, loaded: boolean }} */
const clientsDefaultState = {
  entities: {},
  results: [],
  loading: false,
  loaded: false,
};

/** @type {{ data: Object, loading: boolean, loaded: boolean; }} */
const statsDefaultState = {
  data: {},
  loading: false,
  loaded: false,
};

const localStorageThemeName = 'theme';

/**
 * @typedef {'error'|'info'|'warning'|'success'} TGeneralContext$notificationLevel
 */

/**
 * @typedef {Object} TGeneralContext$notification
 * @property {string} TGeneralContext$notification.id
 * @property {string} TGeneralContext$notification.message
 * @property {TGeneralContext$notificationLevel} TGeneralContext$notification.level
 * @property {number} TGeneralContext$notification.timeout
 */

/**
 * @callback TGeneralContext$addNotification
 * @param {string} message
 * @param {Object} [options]
 * @param {TGeneralContext$notificationLevel} [options.level='error']
 * @param {number} [options.timeout]
 * @param {boolean} [options.override=false]
 * @returns {void}
 */

/**
 * @callback TGeneralContext$removeNotification
 * @param {string} id
 * @returns {void}
 */

/**
 * @callback TGeneralContext$clearNotifications
 * @returns {void}
 */

export const GeneralContext = React.createContext({
  // /** @type {{ entities: Object, results: string[], loading: boolean, loaded: boolean }} */
  clients: clientsDefaultState,
  /** @type {Object} */
  clientWithHighestTipset: null,
  /** @type {{ data: Object, loading: boolean, loaded: boolean; }} */
  stats: statsDefaultState,
  /** @type {FS$Network|null} */
  network: null,
  /** @type {(network: FS$Network) => void} */
  setNetwork: noop,
  theme: window.localStorage.getItem(localStorageThemeName),
  colorScheme: /** @type {'light'|'dark'} */ (window.matchMedia(
    '(prefers-color-scheme: dark)'
  ).matches
    ? 'dark'
    : 'light'),
  /** @type {(themeName: ?string) => void} */
  setTheme: noop,
  notificationActions: {
    /** @type {TGeneralContext$addNotification} */
    addNotification: noop,
    /** @type {TGeneralContext$removeNotification} */
    removeNotification: noop,
    /** @type {TGeneralContext$clearNotifications} */
    clearNotifications: noop,
  },
});

const notificationsNode = document.querySelector('#notifications-root');
const storedTheme = window.localStorage.getItem(localStorageThemeName);

export const GeneralProvider = ({ children }) => {
  /** @type {[TGeneralContext$notification[], React.Dispatch<React.SetStateAction<TGeneralContext$notification[]>>]} */
  const [notifications, setNotifications] = React.useState([]);
  /** @type {[FS$Network|null, React.Dispatch<React.SetStateAction<FS$Network|null>>]} */
  const [network, setNetwork] = React.useState(null);
  const [clients, setClients] = React.useState(() => clientsDefaultState);
  const [stats, setStats] = React.useState(() => statsDefaultState);
  const [theme, setTheme] = React.useState(storedTheme || 'auto');
  /** @type {'light'|'dark'} */
  const osThemeInit = window.matchMedia('(prefers-color-scheme: dark)').matches
    ? 'dark'
    : 'light';
  const [osTheme, setOsTheme] = React.useState(osThemeInit);

  const location = useLocation();

  const addNotification = React.useCallback(
    (message, { level = 'error', timeout, override = false } = {}) => {
      if (message) {
        const nItem = { id: nanoid(), message, level, timeout };

        setNotifications((ns) => [...(override ? [] : ns), nItem]);
      }
    },
    [setNotifications]
  );

  const removeNotification = React.useCallback(
    (id) => {
      setNotifications((prevNotifications) =>
        prevNotifications.filter((n) => n.id !== id)
      );
    },
    [setNotifications]
  );

  const clearNotifications = React.useCallback(() => {
    setNotifications([]);
  }, [setNotifications]);

  const fetchClients = () => {
    setClients((prevClients) => ({
      ...prevClients,
      entities: {},
      results: [],
      loading: true,
    }));

    window
      .fetch(`${network?.HTTP_API ?? ''}/clients`)
      .then((response) => response.json())
      .then((data) => {
        const { entities, results } = normalizeItems(data, 'id');

        setClients({
          entities,
          results,
          loading: false,
          loaded: true,
        });
        return data;
      })
      .catch((err) => {
        console.log(err);
        setClients((prevClients) => ({
          ...prevClients,
          loading: false,
        }));
      });
  };

  const fetchStats = () => {
    setStats((prevStats) => ({
      ...prevStats,
      data: {},
      loading: true,
    }));

    window
      .fetch(`${network?.HTTP_API ?? ''}/stats`)
      .then((response) => response.json())
      .then((data) => {
        setStats({
          data,
          loading: false,
          loaded: true,
        });
      })
      .catch((err) => {
        console.log(err);
        setStats((prevClients) => ({
          ...prevClients,
          loading: false,
        }));
      });
  };

  const fetchClientsRef = React.useRef(fetchClients);
  fetchClientsRef.current = fetchClients;
  const fetchStatsRef = React.useRef(fetchStats);
  fetchStatsRef.current = fetchStats;

  React.useEffect(() => {
    const foundNetwork = networks.find((ntw) => ntw.path === location.pathname);

    if (foundNetwork) {
      setNetwork(foundNetwork);
    }
  }, [location.pathname]);

  const wsMessageHandler = React.useCallback((event) => {
    let parsedMessage;
    try {
      parsedMessage = JSON.parse(event.data);
    } catch (e) {
      console.error(e);
    }

    if (parsedMessage) {
      switch (parsedMessage.type) {
        case 'client-add':
          setClients((prevClients) => ({
            ...prevClients,
            entities: {
              ...prevClients.entities,
              [parsedMessage.data.id]: parsedMessage.data,
            },
            results: prevClients.results.includes(parsedMessage.data.id)
              ? prevClients.results
              : [...prevClients.results, parsedMessage.data.id],
          }));
          break;
        case 'client-update':
          setClients((prevClients) => ({
            ...prevClients,
            entities: {
              ...prevClients.entities,
              [parsedMessage.data.id]: parsedMessage.data,
            },
          }));
          break;
        case 'client-remove':
          setClients((prevClients) => {
            const entities = { ...prevClients.entities };
            delete entities[parsedMessage.data.id];

            return {
              ...prevClients,
              entities,
              results: prevClients.results.filter(
                (res) => res !== parsedMessage.data.id
              ),
            };
          });
          break;
        case 'stats-update':
          setStats((prevStats) => ({
            ...prevStats,
            data: parsedMessage.data,
          }));
          break;
        default:
          console.log(
            `Message with type "${parsedMessage.type}" is not processed`
          );
      }
    }
  }, []);

  const wsRef = React.useRef(
    new Websockets(wsMessageHandler, () => {
      fetchClientsRef.current();
      fetchStatsRef.current();
    })
  );

  React.useEffect(() => {
    if (network) {
      fetchClientsRef.current();
      fetchStatsRef.current();
      wsRef.current.start(network.WS_API);
    }
  }, [network]);

  const setThemeHandler = (th) => {
    setTheme(th);
    window.localStorage.setItem(localStorageThemeName, th);
  };

  React.useEffect(() => {
    setOsTheme(
      window.matchMedia('(prefers-color-scheme: dark)').matches
        ? 'dark'
        : 'light'
    );

    window.matchMedia('(prefers-color-scheme: dark)').addListener((e) => {
      console.log('is dark', e.matches);

      setOsTheme(e.matches ? 'dark' : 'light');
    });
  }, []);

  React.useEffect(() => {
    switch (theme) {
      case 'light':
      case 'dark':
        document.body.setAttribute('data-theme', theme);
        break;
      default:
        document.body.setAttribute('data-theme', osTheme);
    }
  }, [theme, osTheme]);

  const clientWithHighestTipset = React.useMemo(() => {
    const nodesList = Object.values(clients.entities);

    return nodesList.length
      ? nodesList.reduce((max, curr) => {
          return max.chainHead?.tipsetHeight ??
            curr.chainHead?.tipsetHeight < 0 ??
            0
            ? max
            : curr;
        })
      : null;
  }, [clients.entities]);

  return (
    <GeneralContext.Provider
      value={{
        clients,
        clientWithHighestTipset,
        stats,
        network,
        setNetwork,
        theme,
        setTheme: setThemeHandler,
        colorScheme: theme === 'light' || theme === 'dark' ? theme : osTheme,
        notificationActions: {
          addNotification,
          removeNotification,
          clearNotifications,
        },
      }}
    >
      {children}
      {notificationsNode &&
        ReactDOM.createPortal(
          <>
            {notifications.map((n) => (
              <Notification key={n.id} n={n} onClose={removeNotification} />
            ))}
          </>,
          notificationsNode
        )}
    </GeneralContext.Provider>
  );
};
