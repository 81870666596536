import React, { Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { Header } from '../Header';
import { Footer } from '../Footer';

import networksConfig from '../../networksConfig.json';

import { PageLoading } from '../PageLoading';

const DashboardPage = lazy(() => import('../DashboardPage'));
const AddNodePage = lazy(() => import('../AddNodePage'));
const PrivacyPolicyPage = lazy(() => import('../PrivacyPolicyPage'));
const AboutPage = lazy(() => import('../AboutPage'));

const App = () => {
  return (
    <>
      <Header />
      <main id="main">
        <Suspense fallback={<PageLoading />}>
          <Switch>
            <Route exact path="/add-node" component={AddNodePage} />
            <Route exact path="/privacy-policy" component={PrivacyPolicyPage} />
            <Route exact path="/about" component={AboutPage} />
            {networksConfig.networks.map((network) => (
              <Route
                key={network.path}
                exact
                path={network.path}
                component={DashboardPage}
              />
            ))}

            <Redirect to="/" />
          </Switch>
        </Suspense>
      </main>

      <Footer />
    </>
  );
};

export default App;
