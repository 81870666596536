import React from 'react';
import cn from 'classnames';
import s from './s.module.css';
import { GeneralContext } from '../../context/general';

import { SunIcon, MoonIcon } from '../Icons';

const themes = ['light', 'dark'];

export function ThemeSwitcher({ className }) {
  const { theme, setTheme } = React.useContext(GeneralContext);
  const changeHandler = (e) => {
    setTheme(e.currentTarget.name);
  };

  return (
    <div className={cn(s.buttons, className)}>
      <button
        type="button"
        name="light"
        onClick={changeHandler}
        className={cn(s.button, { [s.active]: theme === 'light' })}
      >
        <SunIcon />
      </button>
      <button
        type="button"
        name="auto"
        onClick={changeHandler}
        className={cn(s.button, s.auto, {
          [s.active]: !themes.includes(theme),
        })}
      >
        AUTO
      </button>
      <button
        type="button"
        name="dark"
        onClick={changeHandler}
        className={cn(s.button, { [s.active]: theme === 'dark' })}
      >
        <MoonIcon />
      </button>
    </div>
  );
}
