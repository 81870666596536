import React from 'react';

import { SyncIcon } from '../Icons';
import s from './s.module.css';

export const PageLoading = () => (
  <div className={s.wrap}>
    <SyncIcon style={{ width: 40, height: 40 }} className={s.icon} />
  </div>
);
