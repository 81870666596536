import React from 'react';
import { NavLink } from 'react-router-dom';

import { GeneralContext } from '../../context/general';
import { ThemeSwitcher } from '../ThemeSwitcher';
import { InfoIcon, LockIcon, IssueIcon } from '../Icons';

import s from './s.module.css';

export const Footer = () => {
  const { network } = React.useContext(GeneralContext);
  return (
    <footer className={s.footer}>
      <nav className={s.nav}>
        <NavLink
          to={{
            pathname: '/about',
            state: network?.path,
          }}
          className={s.link}
        >
          <InfoIcon />
          <span>About</span>
        </NavLink>
        <NavLink
          to={{
            pathname: '/privacy-policy',
            state: network?.path,
          }}
          className={s.link}
        >
          <LockIcon />
          <span>Privacy Policy</span>
        </NavLink>
        <a
          href="https://github.com/Digital-MOB-Filecoin/filstats-cli/issues"
          target="_blank"
          rel="noreferrer noopener"
          className={s.link}
        >
          <IssueIcon />
          <span>Report Issue</span>
        </a>
      </nav>
      <ThemeSwitcher />
    </footer>
  );
};
