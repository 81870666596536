import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import cn from 'classnames';

import { LogoIcon } from '../Icons';

import s from './s.module.css';
import { GeneralContext } from '../../context/general';

const pagesWithCloseButton = ['/add-node', '/about', '/privacy-policy'];

export const Header = () => {
  const { network } = React.useContext(GeneralContext);
  const { pathname, state } = useLocation();
  const displayCloseButton = pagesWithCloseButton.includes(pathname);

  return (
    <header className={s.header}>
      <NavLink to="/" className={s.logoWrap}>
        <LogoIcon />
        <span>Filstats</span>
      </NavLink>
      {displayCloseButton ? (
        <NavLink
          to={state || '/'}
          className={cn('button button--secondary', s.addLink)}
        >
          Close
        </NavLink>
      ) : (
        <NavLink
          to={{
            pathname: '/add-node',
            state: network?.path,
          }}
          className={cn('button button--primary', s.addLink)}
        >
          Add a new node
        </NavLink>
      )}
    </header>
  );
};
