export function normalizeItems(rawItems, idName = 'id') {
  return rawItems.reduce(
    (acc, item) => {
      return {
        entities: {
          ...acc.entities,
          [item[idName]]: item,
        },
        results: [...acc.results, item[idName]],
      };
    },
    {
      entities: {},
      results: [],
    }
  );
}
