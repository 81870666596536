export class Websockets {
  socket;

  first = true;

  constructor(messageHandler, closeHandler) {
    this.messageHandler = messageHandler;
    this.closeHandler = closeHandler;
  }

  start = (url) => {
    if (this.socket) {
      // tslint:disable-next-line: no-empty
      this.socket.onclose = () => {};
      this.socket.close();
    }

    this.url = url || this.url;
    this.socket = new window.WebSocket(this.url);
    window.ws = this.socket;

    this.socket.onerror = this.onerror;
    this.socket.onmessage = this.onmessage;
    this.socket.onopen = this.onopen;
    this.socket.onclose = this.onclose;
  };

  stop = () => {
    if (!this.socket) {
      return;
    }
    // tslint:disable-next-line: no-empty
    this.socket.onclose = () => {};
    this.socket.close();
  };

  onopen = () => {
    // if (this.first) {
    //   this.first = false;
    // } else {
    //   this.main.notify.success('Connected to websockets', true, 3000);
    // }

    // this.main.clientStore.fetch().then(() => {
    //     this.main.closeLoading();
    // }).catch((error) => {
    //     this.main.notify.error("API error: " + error.message, false);
    // });
  };

  onmessage = (evt) => {
    // let obj = null;
    // try {
    //   obj = JSON.parse(msg.data);
    // } catch (e) {
    //   return e;
    // }

    // if (obj !== null) {
    //   switch (obj.topic) {
    //     case 'clients':
    //       // tslint:disable-next-line:no-floating-promises
    //       this.main.clientStore.fetchDebounced();
    //       break;
    //   }
    // }
    this.messageHandler(evt);
  };

  onerror = (error) => {
    this.socket.close();
    // this.main.closeLoading();
  };

  onclose = (event) => {
    // if (event.wasClean) {
    //     this.main.notify.error(
    //         `Websocket connection closed cleanly, code=${event.code} reason=${event.reason}`,
    //         true, 3000);
    // } else {
    //     this.main.notify.error("Websocket connection died. Attempting reconnect",
    //         true, 3000);
    // }
    this.closeHandler();
    setTimeout(() => {
      this.start();
    }, 5000);
  };
}
