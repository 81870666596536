import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { GeneralProvider } from './context/general';
import { I18nProvider } from './context/i18n';
import App from './components/App';
import './index.css';

// polyfill
import 'wicg-inert';

import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <GeneralProvider>
        <I18nProvider>
          <App />
        </I18nProvider>
      </GeneralProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

document.body.addEventListener('mousedown', () => {
  document.body.classList.add('using-mouse');
});

document.body.addEventListener('keydown', (event) => {
  if (event.key === 'Tab') {
    document.body.classList.remove('using-mouse');
  }
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
