import React from 'react';

export const LogoIcon = ({ style, ...props }) => (
  <svg
    viewBox="0 0 40 40"
    style={{ width: 40, height: 40, ...style }}
    {...props}
  >
    <g fillRule="nonzero">
      <path
        fill="var(--color-brand)"
        d="M39.784 22.918c-1.612 10.926-11.775 18.477-22.701 16.866C6.157 38.173-1.395 28.009.217 17.083 1.827 6.157 11.99-1.395 22.917.217c10.926 1.612 18.476 11.775 16.867 22.7"
      />
      <path
        fill="var(--color-white)"
        d="M28.969 8.003c-4.042-2.556-6.934 3.072-7.002 3.245-.722 1.406-1.314 3.559-1.682 5.166l-5.265-.78-.208 1.421 5.2.768c-.145.74-.216 1.22-.216 1.22l-.285 1.561h.015l-.019.093-5.147-.759-.192 1.431 5.098.744c-1.341 6.764-2.332 8.801-3.33 9.91-.204.239-1.635 1.199-2.356-.517-.502-1.196-1.456-1.031-1.456-1.031-.27-.025-1.524.783.44 2.025 4.026 2.558 6.934-3.072 7.002-3.243.722-1.406 1.682-5.206 2.05-6.813l5.3.784.207-1.419-5.218-.75c.142-.743.216-1.22.216-1.22l.257-1.567h-.015l.015-.093 5.16.762.195-1.421-5.098-.762C23.977 9.994 24.6 9.6 25.596 8.479c.205-.238 1.635-1.195 2.357.518.502 1.195 1.468 1.031 1.468 1.031.27.028 1.52-.784-.443-2.025"
      />
    </g>
  </svg>
);

export const FilterIcon = ({ style, ...props }) => (
  <svg
    viewBox="0 0 24 24"
    style={{ width: 24, height: 24, ...style }}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M13 15c1.306 0 2.418.835 2.83 2H21v2h-5.171a3.001 3.001 0 01-5.658 0H3v-2h7.17A3.001 3.001 0 0113 15zm0 2a1 1 0 00-.117 1.993L13 19a1 1 0 00.117-1.993L13 17zM6 9c1.306 0 2.418.835 2.83 2H21v2H8.829a3.001 3.001 0 01-5.658 0H3v-2h.17A3.001 3.001 0 016 9zm0 2a1 1 0 00-.117 1.993L6 13a1 1 0 00.117-1.993L6 11zm11-8c1.306 0 2.418.835 2.83 2H21v2h-1.171a3.001 3.001 0 01-5.658 0H3V5h11.17A3.001 3.001 0 0117 3zm0 2a1 1 0 00-.117 1.993L17 7a1 1 0 00.117-1.993L17 5z"
    />
  </svg>
);

export const CloseIcon = ({ style, ...props }) => (
  <svg
    viewBox="0 0 24 24"
    style={{ width: 24, height: 24, ...style }}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16.95 8.464L13.414 12l3.536 3.536-1.414 1.414L12 13.414 8.464 16.95 7.05 15.536 10.586 12 7.05 8.464 8.464 7.05 12 10.586l3.536-3.536z"
    />
  </svg>
);

export const ExpandIcon = ({ style, ...props }) => (
  <svg
    viewBox="0 0 24 24"
    style={{ width: 24, height: 24, ...style }}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M22 2v20h-5v-2h3v-3h2V7h-2V4h-3V2h5zM4 16.999V20h3v2H2v-5l2-.001zM2 2h5v2H4v2.999L2 7V2z"
    />
  </svg>
);

export const SearchIcon = ({ style, ...props }) => (
  <svg
    viewBox="0 0 24 24"
    style={{ width: 24, height: 24, ...style }}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.5 5a6.5 6.5 0 015.249 10.335l3.09 3.09a.5.5 0 010 .707l-.707.707a.5.5 0 01-.707 0l-3.09-3.09A6.5 6.5 0 1111.5 5zm0 2a4.5 4.5 0 100 9 4.5 4.5 0 000-9z"
    />
  </svg>
);

export const CheckboxOn = ({ style, ...props }) => (
  <svg
    viewBox="0 0 24 24"
    style={{ width: 24, height: 24, ...style }}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M14.872 4c1.783 0 2.43.186 3.082.534.652.349 1.163.86 1.512 1.512.348.652.534 1.299.534 3.082v5.744c0 1.783-.186 2.43-.534 3.082a3.635 3.635 0 01-1.512 1.512c-.652.348-1.299.534-3.082.534H9.128c-1.783 0-2.43-.186-3.082-.534a3.635 3.635 0 01-1.512-1.512C4.186 17.302 4 16.655 4 14.872V9.128c0-1.783.186-2.43.534-3.082a3.635 3.635 0 011.512-1.512C6.698 4.186 7.345 4 9.128 4h5.744zm-.232 5.267l-3.846 3.85-1.761-1.763-.983.983 2.744 2.747 4.829-4.833-.983-.984z"
    />
  </svg>
);

export const CheckboxOff = ({ style, ...props }) => (
  <svg
    viewBox="0 0 24 24"
    style={{ width: 24, height: 24, ...style }}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8.856 4.002L14.872 4c1.783 0 2.43.186 3.082.534.652.349 1.163.86 1.512 1.512.348.652.534 1.299.534 3.082v5.744c0 1.783-.186 2.43-.534 3.082a3.635 3.635 0 01-1.512 1.512c-.652.348-1.299.534-3.082.534H9.128c-1.783 0-2.43-.186-3.082-.534a3.635 3.635 0 01-1.512-1.512c-.33-.618-.514-1.23-.532-2.81L4 9.128c0-1.783.186-2.43.534-3.082a3.635 3.635 0 011.512-1.512c.618-.33 1.23-.514 2.81-.532zM8.89 6l-.416.008c-.821.025-1.15.11-1.484.289-.304.162-.53.388-.692.692l-.076.155c-.15.345-.213.76-.221 1.745v6.22l.008.416c.025.821.11 1.15.289 1.484.162.304.388.53.692.692l.155.076c.345.15.76.213 1.745.221l5.982.001c1.324 0 1.727-.078 2.138-.298.304-.162.53-.388.692-.692l.076-.155c.15-.345.213-.76.221-1.745L18 9.128c0-1.324-.078-1.727-.298-2.138a1.635 1.635 0 00-.692-.692l-.155-.076c-.345-.15-.76-.213-1.745-.221H8.89z"
    />
  </svg>
);

export const RadioOn = ({ style, ...props }) => (
  <svg
    viewBox="0 0 24 24"
    style={{ width: 24, height: 24, ...style }}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 3a9 9 0 110 18 9 9 0 010-18zm0 2a7 7 0 100 14 7 7 0 000-14zm0 2a5 5 0 110 10 5 5 0 010-10z"
    />
  </svg>
);

export const RadioOff = ({ style, ...props }) => (
  <svg
    viewBox="0 0 24 24"
    style={{ width: 24, height: 24, ...style }}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 3a9 9 0 110 18 9 9 0 010-18zm0 2a7 7 0 100 14 7 7 0 000-14z"
    />
  </svg>
);

export const SortIcon = ({ style, sortType, activeColor, ...props }) => (
  <svg
    viewBox="0 0 24 24"
    style={{ width: 24, height: 24, ...style }}
    {...props}
  >
    <path
      fill={sortType === 'ASC' ? activeColor : 'currentColor'}
      fillRule="evenodd"
      d="M8.854 9.146l2.439-2.439a1 1 0 011.414 0l2.44 2.44a.5.5 0 01-.354.853H9.207a.5.5 0 01-.353-.854z"
    />
    <path
      fill={sortType === 'DESC' ? activeColor : 'currentColor'}
      fillRule="evenodd"
      d="M15.146 14.854l-2.439 2.439a1 1 0 01-1.414 0l-2.44-2.44A.5.5 0 019.208 14h5.586a.5.5 0 01.353.854z"
    />
  </svg>
);

export const SyncIcon = ({ style, sortType, activeColor, ...props }) => (
  <svg
    viewBox="0 0 26 24"
    style={{ width: 24, height: 24, ...style }}
    {...props}
  >
    <g fill="currentColor" fillRule="evenodd">
      <path d="M12 20a8.001 8.001 0 01-7.938-7h2.021a6.002 6.002 0 0011.834 0h2.021A8.001 8.001 0 0112 20z" />
      <path d="M2.982 12.75v7h2v-5h5v-2zM12.1 4.1a8.001 8.001 0 017.939 7h-2.022a6.002 6.002 0 00-11.833 0H4.162a8.001 8.001 0 017.939-7z" />
      <path d="M21.118 11.35v-7h-2v5h-5v2z" />
    </g>
  </svg>
);

export const NodeIcon = ({ style, ...props }) => (
  <svg
    viewBox="0 0 24 24"
    style={{ width: 24, height: 24, ...style }}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="M20 9H4V5h16v4zm-2.5-2.75a.75.75 0 100 1.5.75.75 0 000-1.5zm-2 0a.75.75 0 100 1.5.75.75 0 000-1.5zM20 14H4v-4h16v4zm-2.5-2.75a.75.75 0 100 1.5.75.75 0 000-1.5zm-2 0a.75.75 0 100 1.5.75.75 0 000-1.5zM20 19H4v-4h16v4zm-2.5-2.75a.75.75 0 100 1.5.75.75 0 000-1.5zm-2 0a.75.75 0 100 1.5.75.75 0 000-1.5z"
    />
  </svg>
);

export const BlockIcon = ({ style, ...props }) => (
  <svg
    viewBox="0 0 24 24"
    style={{ width: 24, height: 24, ...style }}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.998 3.91L4.972 6.54 4 6.914v8.965l.828.444 6.5 3.25.672.32 7.169-3.57.828-.444V6.913l-.972-.37-6.5-2.437-.527-.196zM12 6.044l6 2.25v.034l-6 2.438-6-2.438v-.034l6-2.25zm1 11.125v-4.653l5-2.032v4.185l-5 2.5z"
    />
  </svg>
);

export const PeersIcon = ({ style, ...props }) => (
  <svg
    viewBox="0 0 24 24"
    style={{ width: 24, height: 24, ...style }}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="M17 11c1.103 0 2-.897 2-2s-.897-2-2-2-2 .897-2 2 .897 2 2 2zm1 1h-2c-.55 0-1.047.222-1.41.581A4.571 4.571 0 0116.939 16H20v-2c0-1.103-.897-2-2-2zm-8 0c1.934 0 3.5-1.566 3.5-3.5S11.934 5 10 5a3.498 3.498 0 00-3.5 3.5c0 1.934 1.566 3.5 3.5 3.5zm2.4 1h-.26c-.65.313-1.371.5-2.14.5a4.933 4.933 0 01-2.14-.5H7.6A3.6 3.6 0 004 16.6V19h12v-2.4a3.6 3.6 0 00-3.6-3.6z"
    />
  </svg>
);

export const ChartIcon = ({ style, ...props }) => (
  <svg
    viewBox="0 0 48 48"
    style={{ width: 48, height: 48, ...style }}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5 16h8v27H5zM20 5h8v38h-8zm15 6h8v32h-8z"
    />
  </svg>
);

export const RoundChartIcon = ({ style, ...props }) => (
  <svg
    viewBox="0 0 48 48"
    style={{ width: 48, height: 48, ...style }}
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="currentColor"
        d="M22.4 6.4v18.4l13.01 13.01A18.342 18.342 0 0122.4 43.2C12.238 43.2 4 34.962 4 24.8 4 14.638 12.238 6.4 22.4 6.4z"
      />
      <path
        fill="currentColor"
        d="M24.8 4c10.162 0 18.4 8.238 18.4 18.4H24.8zM44 24.8a18.34 18.34 0 01-5.328 12.949L25.6 24.8z"
      />
    </g>
  </svg>
);

export const MapIcon = ({ style, ...props }) => (
  <svg
    viewBox="0 0 48 48"
    style={{ width: 48, height: 48, ...style }}
    {...props}
  >
    <path
      fill="currentColor"
      d="M4 13.167L14.833 9v26.667L4 39.833zm25.833 0L18.167 9v26.667l11.666 4.166zm3.334 0L44 9v26.667l-10.833 4.166z"
    />
  </svg>
);

export const InfoIcon = ({ style, ...props }) => (
  <svg
    viewBox="0 0 24 24"
    style={{ width: 24, height: 24, ...style }}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 4a8 8 0 110 16 8 8 0 010-16zm1 7h-2v6h2v-6zm0-4h-2v2h2V7z"
    />
  </svg>
);

export const LockIcon = ({ style, ...props }) => (
  <svg
    viewBox="0 0 24 24"
    style={{ width: 24, height: 24, ...style }}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 3a5 5 0 014.995 4.783L17 8v2a1 1 0 011 1v8a1 1 0 01-1 1H7a1 1 0 01-1-1v-8a1 1 0 011-1V8a5 5 0 015-5zm0 9a1.5 1.5 0 00-1.053 2.568l.108.097L10.5 18h3l-.554-3.336A1.5 1.5 0 0012 12zm0-7a3 3 0 00-2.995 2.824L9 8v2h6V8a3 3 0 00-3-3z"
    />
  </svg>
);

export const IssueIcon = ({ style, ...props }) => (
  <svg
    viewBox="0 0 24 24"
    style={{ width: 24, height: 24, ...style }}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 20a8 8 0 110-16 8 8 0 010 16zm-1-7h2V7h-2v6zm0 4h2v-2h-2v2z"
    />
  </svg>
);

export const SunIcon = ({ style, ...props }) => (
  <svg
    fill="none"
    viewBox="0 0 16 16"
    style={{ width: 16, height: 16, ...style }}
    {...props}
  >
    <circle cx="8" cy="8" r="4.343" fill="currentColor" />
    <rect
      x="7.314"
      width="1.371"
      height="2.743"
      rx=".686"
      fill="currentColor"
    />
    <rect
      x="13.172"
      y="1.858"
      width="1.371"
      height="2.743"
      rx=".686"
      transform="rotate(45 13.172 1.858)"
      fill="currentColor"
    />
    <rect
      x="7.314"
      y="13.257"
      width="1.371"
      height="2.743"
      rx=".686"
      fill="currentColor"
    />
    <rect
      x="3.798"
      y="11.232"
      width="1.371"
      height="2.743"
      rx=".686"
      transform="rotate(45 3.798 11.232)"
      fill="currentColor"
    />
    <rect
      x="16"
      y="7.314"
      width="1.371"
      height="2.743"
      rx=".686"
      transform="rotate(90 16 7.314)"
      fill="currentColor"
    />
    <rect
      x="2.743"
      y="7.314"
      width="1.371"
      height="2.743"
      rx=".686"
      transform="rotate(90 2.743 7.314)"
      fill="currentColor"
    />
    <rect
      x="14.142"
      y="13.172"
      width="1.371"
      height="2.743"
      rx=".686"
      transform="rotate(135 14.142 13.172)"
      fill="currentColor"
    />
    <rect
      x="4.768"
      y="3.798"
      width="1.371"
      height="2.743"
      rx=".686"
      transform="rotate(135 4.768 3.798)"
      fill="currentColor"
    />
  </svg>
);

export const MoonIcon = ({ style, ...props }) => (
  <svg
    fill="none"
    viewBox="0 0 16 15"
    style={{ width: 16, height: 15, ...style }}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.582 9.559A8 8 0 013.418.442a8 8 0 1012.164 9.117z"
      fill="currentColor"
    />
    <rect x="6" y="1" width="1" height="3" rx=".5" fill="currentColor" />
    <rect x="9" y="6" width="1" height="3" rx=".5" fill="currentColor" />
    <rect x="13" y="3" width="1" height="3" rx=".5" fill="currentColor" />
    <rect
      x="8"
      y="2"
      width="1"
      height="3"
      rx=".5"
      transform="rotate(90 8 2)"
      fill="currentColor"
    />
    <rect
      x="11"
      y="7"
      width="1"
      height="3"
      rx=".5"
      transform="rotate(90 11 7)"
      fill="currentColor"
    />
    <rect
      x="15"
      y="4"
      width="1"
      height="3"
      rx=".5"
      transform="rotate(90 15 4)"
      fill="currentColor"
    />
  </svg>
);
